export const saveMixin = {
  data: () => ({}),
  methods: {
    // Сохранение данных услуги [UserService]
    saveUserService (service, nextlink = false, data = {}) {
      this.$store.commit('loading', true)
      this.axios.get('user_service', this.getUserServiceParams(service)).then((response) => {
        const userServiceId = response.data.id || 0
        const requestData = Object.assign(
          this.getUserServiceParams(service).params,
          { status: this.$store.state.service_status },
          data
        )
        this.axios.put('user_service/' + userServiceId, requestData).then(() => {
          this.$store.commit('loading', false)
          if (nextlink) this.$router.push(nextlink)
        }).catch((e) => { this.catchError(e) })
      }).catch((e) => { this.catchError(e) })
    },
    // Сохранение дополнительной информации об услуге [ServiceInfo]
    saveServiceInfo (service, nextlink = false, data = {}) {
      this.$store.commit('loading', true)
      this.axios.get('service_info', this.getUserServiceParams(service)).then((response) => {
        const serviceInfoId = response.data.id || 0
        this.axios.put('/service_info/' + serviceInfoId, data).then(() => {
          this.$store.commit('loading', false)
          if (nextlink) this.$router.push(nextlink)
        }).catch((e) => { this.catchError(e) })
      }).catch((e) => { this.catchError(e) })
    },
    getUserServiceParams (service) {
      return {
        params: {
          user_id: this.$store.state.user ? this.$store.state.user.id : 0,
          service_id: this.$store.state.services[service]
        }
      }
    },
    catchError (error) {
      this.$store.commit('loading', false)
      this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
      this.$scrollTo('.error__container', 300, { offset: -400 })
    }
  }
}
