<template>
  <div class="card__container">
    <div class="card" :class="cardData.class || ''" @click="showInformation(cardData.id, cardData.class || null)">
      <div class="card__name">
        <span class="card__label">CB</span>
        <span class="card__name_bank">{{ 'card_bank' | localize }}</span>
      </div>
      <div class="card__chip"></div>
      <div class="card__wireless icon-wireless"></div>
      <div class="card__number">{{ cardData.number }}</div>
      <div class="card__expire">{{ cardData.dt }}</div>
    </div>

    <button type="button" class="button button__form" @click="$store.commit('togglePopupText', { id: 'card' })" v-if="cardData.button && cardData.button == 'addNewCard'">
      {{ "addNewCard" | localize }}
    </button>

    <button type="button" class="button button__form" @click="$store.commit('togglePopupText', { id: cardData.id })" v-else-if="select">
      {{ "select" | localize }}
    </button>

    <button type="button" class="button button__form" @click="$store.commit('togglePopupText', { id: 1 })" v-if="cardData.button && cardData.button == 'removeCard'">
      {{ "removeCard" | localize }}
    </button>
  </div>
</template>

<script>
export default {
  props: { cardData: { type: Object }, select: { type: Boolean, default: false } },
  name: 'Card',
  methods: {
    showInformation (id, cls) {
      if (cls) return
      this.$router.push('/profile/payments/' + id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";

.content .content__container .card__container  {
  .button {
    margin: 0 25px 100px;
  }
}

.card__container_right {
  position: absolute;
  right: 0;
  top: 0;
}

.card {
  width: 330px;
  height: 210px;
  color: $white;
  direction: ltr;
  background: linear-gradient(217deg, #1B5CF6 1.87%, #429EFF 50.93%, #CB5BAA 99.33%);
  position: relative;
  border-radius: 17px;
  margin: 0 25px 50px;
  cursor: pointer;

  &__name {
    position: absolute;
    left: 30px;
    top: 27px;
    display: flex;
    align-items: center;
    width: 200px;
  }
  &__label {
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    margin-right: 12px;
  }
  &__name_bank {
    border-left: 1px solid $white;
    padding-left: 12px;
    font-weight: 500;
    font-size: 14px;
    height: 19px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__number {
    position: absolute;
    left: 30px;
    bottom: 47px;
    letter-spacing: -0.1em;
    font-size: 22px;
    font-family: monospace;
    color: $white;
  }
  &__expire {
    position: absolute;
    left: 31px;
    bottom: 20px;
    font-size: 14px;
    font-family: monospace;
    color: rgba(255, 255, 255, 0.6);
  }

  &__chip {
    position: absolute;
    left: 30px;
    top: 50%;
    margin-top: -20px;
    width: 48px;
    height: 40px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }

  &__wireless {
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -20px;
    width: 30px;
    height: 40px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.3);

    &::before {
      margin: 0;
    }
  }

  &__default {
    cursor: default;
    background: none;
    border: 2px solid $white;
    box-sizing: border-box;

    .card__chip {
      background: $white;
    }

    .card__wireless {
      color: $white;
    }
  }
}

#app.locale_heb {
  .card__container_right {
    right: auto;
    left: 0;
  }
}

#app.light-theme {
  .card__default {
    color: $black;
    border: 2px solid $black;

    .card__name_bank {
      color: $black;
      border-left: 1px solid $black;
    }

    .card__number {
      color: $black;
    }
    
    .card__expire {
      color: rgba(0, 0, 0, 0.6);
    }

    .card__chip {
      background: $black;
    }

    .card__wireless {
      color: $black;
    }
  }
}

// Media style
@media screen and (max-width: 1024px) {
  .content .content__container .card__container {
    margin-bottom: 50px;
    text-align: center;
  }
  .card {
    margin: 0 auto 30px;
    width: 100%;
    max-width: 330px;
    min-width: 240px;
  }
  .content .content__container .card__container .button {
    margin: 0;
    max-width: 330px;
  }
}
@media screen and (max-width: 1700px) {
  .card__container_right {
    position: static;
    margin-bottom: 50px;
  }
}
</style>
