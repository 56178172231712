<template>
  <div class="payment__container">
    <div class="payment__container_block">
      <div class="payment__container_card">
        <Card :cardData="cardData" :key="keyCard" />
        <div class="payment__container_card_link">
          <a href="#" v-if="cardData.id" @click.prevent="selectCard">{{ "selectCard" | localize }}</a>
          <a href="#" v-else @click.prevent="$store.commit('togglePopupText', { id: 'card' })">{{ "addNewCard" | localize }}</a>
        </div>
      </div>
      <div class="payment__container_information">
        <div class="payment__container_title">
          {{ "amount_to_paid" | localize }}
          <span v-if="service == 1 || service == 3">{{ amount | filterSum }}<span class="icon-₪"></span></span>
          <span v-else>{{ amount_economy | filterSum }}<span class="icon-₪"></span></span>
        </div>
        <div class="payment__container_subtitle">
          <span v-if="service == 1 || service == 3">{{ percent.toLocaleString($store.state.lang) }}% {{ 'contract_percent' | localize }}</span>
          <span v-else>{{ percent.toLocaleString($store.state.lang) }}% {{ 'contract_from_economy' | localize }}</span>
        </div>
        <div class="payment__container_confirm">
          <v-checkbox
            v-model="agree"
            @change="checkForm($v.agree)"
            hide-details
            on-icon="icon-ckeckbox-on"
            off-icon="icon-ckeckbox-off"
            :class="{ invalid: $v.agree.$dirty && $v.agree.$invalid }"
          >
            <template v-slot:label>
              <input type="hidden" ref="agree" />
              {{ 'agree_message' | localize }}
              <router-link class="container_confirm-text" :to="{ name: 'Privacy policy', params: { routeName: $router.currentRoute.name } }">{{ "registration_text3" | localize }}</router-link>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <app-button
      labelBtn="pay"
      :on-click="payService"
      class="payment__container_button"
      :disabled="!success"
    />
  </div>
</template>

<script>
import Card from '@/components/profile/Card'
import AppButton from '@/components/profile/AppButton'
import { formMixin } from '@/mixins/formMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

export default {
  name: 'PaymentService',
  components: { AppButton, Card },
  mixins: [formMixin, saveMixin],
  props: {
    service: { type: Number, required: true },
    card_id: { type: Number, default: null },
    from_payments: { type: Boolean, default: false }
  },
  data () {
    return {
      cardData: {},
      keyCard: 0,
      radioGroup: 1,
      amount: null,
      amount_economy: null,
      percent: null,
      agree: false
    }
  },
  validations: {
    agree: {
      checked () {
        return this.agree || false
      }
    }
  },
  filters: {
    filterSum: (value) => {
      if (!value) return ''
      return value.toLocaleString()
    }
  },
  watch: {
    service: function () {
      this.loadPayment()
    }
  },
  mounted () {
    if (this.service) {
      this.loadPayment()
      this.loadCard()
    }
  },
  methods: {
    loadCard () {
      this.$store.commit('loading', true)
      this.axios
        .get('user_cards', { params: { user_id: this.$store.state.user.id || 0 } })
        .then((response) => {
          this.cardData = (this.card_id ? response.data.find(c => c.id == this.card_id) : response.data[0]) || { class: 'card__default', number: '0000 0000 0000 0000', dt: 'MM/YY' }
          this.$store.commit('loading', false)
        })
        .catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$store.commit('loading', false)
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
    },
    loadPayment () {
      const userId = this.$store.state.user.id || 0
      this.$store.commit('loading', true)
      this.axios
        .get('user_service/payment', {
          params: {
            user_id: userId,
            service_id: this.service
          }
        })
        .then((response) => {
          this.$store.commit('loading', false)
          this.amount = response.data.amount
          this.percent = response.data.percent
          this.economy = response.data.economy
          if (this.economy) {
            this.amount_economy = Math.round(parseInt(this.economy.sum || 0, 10) * this.percent / 100)
          }
        })
        .catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$store.commit('loading', false)
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
    },
    payService () {
      const cardId = this.cardData.id || false

      if (cardId) {
        this.$store.commit('loading', true)
        this.axios
          .post('payment', {
            user_id: this.$store.state.user.id || 0,
            card_id: cardId,
            service_id: this.service,
            amount: this.amount_economy || this.amount,
            description: 'service_payment'
          })
          .then(() => {
            // Finish -> Отмечаем услугу оплаченной
            this.saveUserService('refMortgage', null, { status: 5 })
            this.$store.commit('loading', false)
            this.$store.commit('togglePopupText')
            this.$router.push('/bank-offers')
          })
          .catch((error) => {
            this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
            this.$store.commit('loading', false)
            this.$scrollTo('.error__container', 300, { offset: -400 })
          })
      } else {
        this.$store.commit('togglePopupText', { id: 'card' })
      }
    },
    agreeMessage (value) {
      this.agree = value
    },
    selectCard () {
      this.$store.commit('togglePopupText')
      if (!this.from_payments) this.$router.push({ name: 'payments', params: { select: true, service: this.service } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";


.payment__container {
  text-align: center;

  .v-input {
    width: 100% !important;
  }

  &_block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  &_card {
    text-align: center;
    max-width: 330px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 30px;

    &_link {
      margin-top: -30px;
      
      a {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: inline-block;
        color: $green;
        border-bottom: 1px solid $green;
      }
    }
  }

  &_information {
    text-align: left;
  }

  &_title {
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  &_subtitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 30px;
  }
}

#app.light-theme {
  .payment__container {
    &_subtitle {
      color: rgba(0, 0, 0, 0.5);
    }
    &_card_link a {
      color: $green_dark;
      border-bottom: 1px solid $green_dark;
    }
  }
}
#app.locale_heb {
  .payment__container {
    direction: rtl;

    &_card {
      margin-right: 0;
      margin-left: 30px;
    }

    &_information {
      text-align: right;
    }
  }
}

@media screen and (max-width: 1024px) {
  .payment__container {
    &_block {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
    }

    &_card {
      margin: 0;
      display: block;
      width: 100%;
      margin-bottom: 30px;
    }

    &_card_link {
      margin-top: -10px;
    }

    &_information {
      text-align: center;
    }

    &_title {
      margin-bottom: 5px;
    }

    &_subtitle {
      margin-bottom: 25px;
    }
  }
}
</style>
